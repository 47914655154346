import Title from "../../../components/Title";

import Expertise1 from "../../../assets/img/Expertise/expertise1.png";
import Expertise2 from "../../../assets/img/Expertise/screen_lio.png"
import Expertise3 from "../../../assets/img/Expertise/expertise3.png";
import { useTranslation } from "react-i18next";
import ExpertiseFr from "../../../components/ExpertiseFr";
import ExpertiseCh from "../../../components/ExpertiseCh";


export default function Expertise({ lang = "FR" } : Readonly<{ lang?: string }>) {
    const { t } = useTranslation();

    const datas = [
        {
            id: 100,
            title: lang === "CH" ? "lioSt.expertise.secondPart.title" : "lioLft.expertise.firstPart.title",
            p1: lang === "CH" ? "lioSt.expertise.secondPart.description" : "lioLft.expertise.firstPart.description",
            p2: lang === "CH" ? "" : "lioLft.expertise.firstPart.description2",
            picture: Expertise1,
            icon: "handshake",
        },
        {
            id: 101,
            title: lang === "CH" ? "lioSt.expertise.firstPart.title" : "lioLft.expertise.secondPart.title",
            p1: lang === "CH" ? "lioSt.expertise.firstPart.description" : "lioLft.expertise.secondPart.description",
            p2: "",
            picture: Expertise2,
            icon: "globe",
        },
        {
            id: 102,
            title: lang === "CH" ? "lioSt.expertise.thirdPart.title" : "lioLft.expertise.thirdPart.title",
            p1: lang === "CH" ? "lioSt.expertise.thirdPart.description" : "lioLft.expertise.thirdPart.description",
            p2: "",
            picture: Expertise3,
            icon: "atom",
        },
    ];

    return (
        <div id='expertise' className={`w-full min-h-screen flex items-center justify-center bg-[#FFF]`}>
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12">
                <Title
                    title={lang === "CH" 
                        ? "lioSt.expertise.title"
                        : "lioLft.expertise.title"
                    }
                    subtitle={lang === "CH"
                        ? "lioSt.expertise.subTitle"
                        : "lioLft.expertise.subTitle"
                    }
                    description=""
                    lang={lang}
                />
                {lang === "CH" 
                    ? (
                        <ExpertiseCh
                            lang="CH"
                            datas={datas}
                            t={t}
                        />
                    ) : (
                        <ExpertiseFr
                            lang="FR"
                            datas={datas}
                            t={t}
                        />
                    )
                }
            </div>
        </div>
    );
}
