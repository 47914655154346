import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoLio from "../assets/img/logoLio.png";

export default function NavBarOtherPages() {

    return (
        <div
            className={`h-16 w-full flex justify-center items-center py-2 fixed top-0 left-0 z-50 bg-[#fff] border-b border-b-[#FF0101] transition-all duration-300`}
        >
            <div className="flex flex-row items-center w-full md:w-auto px-4 md:px-0 justify-between gap-8 lg:gap-36">
                <img src={LogoLio} alt="logo lio" className="h-12" />
                <div className="hidden md:flex items-center justify-between gap-4 md:gap-11">
                    <div className="py-0.5 border-t border-b border-[#ff0101]">
                        <a href="/#header" className="cursor-pointer text-sm font-bold text-[#010B70] hover:text-[#010B70]/60 transition-all leading-[24px] tracking-[0.2px]">
                            Accueil
                        </a>
                    </div>
                    <div className="py-0.5 border-t border-b border-[#ff0101]">
                        <a href="/#expertise" className="cursor-pointer text-sm font-bold text-[#010B70] hover:text-[#010B70]/60 transition-all leading-[24px] tracking-[0.2px]">
                            Notre expertise
                        </a>
                    </div>
                    <div className="py-0.5 border-t border-b border-[#ff0101]">
                        <a href="/#labos" className="cursor-pointer text-sm font-bold text-[#010B70] hover:text-[#010B70]/60 transition-all leading-[24px] tracking-[0.2px]">
                            Laboratoires
                        </a>
                    </div>
                </div>
                <div className="flex items-center justify-between w-fit gap-4 md:gap-7">
                    <a href="https://laboratoire.lio-lft.fr/" className="text-sm font-bold leading-[22px] tracking-[0.2px] text-[#010B70]">
                        <span className="hidden md:block">Mon espace</span>
                        <FontAwesomeIcon icon="user" className="md:hidden" />
                    </a>
                    <a href="https://laboratoire.lio-lft.fr/register" className="text-sm font-bold leading-[22px] tracking-[0.2px] text-white rounded-[5px] py-3.5 px-6 bg-[#010B70]">
                        <span className="hidden md:block">Rejoindre Lio <FontAwesomeIcon icon="arrow-right" className="ml-2" /></span>
                        <FontAwesomeIcon icon="plus" className="md:hidden" />
                    </a>
                </div>
            </div>
        </div>
    );
}
