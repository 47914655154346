import VivienFR from "../../../assets/img/Directors/vivien_fr.png";
import ClementFR from "../../../assets/img/Directors/clement_fr.png"

import VivienCH from "../../../assets/img/Directors/vivien_st.png";
import ClementCH from "../../../assets/img/Directors/clement_st.png"
import ThibaultCH from "../../../assets/img/Directors/tmp_st.png"

import { useTranslation } from "react-i18next";

export default function Directors({ lang = "FR" } : Readonly<{ lang?: string }>) {
    const { t } = useTranslation();

    return (
        <div id="vivien" className={`w-full min-h-screen flex items-center justify-center ${lang === "CH" ? "bg-white" : "bg-royal-10"}`}>
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12">
                <div className="flex flex-col md:flex-row flex-between">
                    <div className="w-full md:w-1/2 grow flex items-center justify-center">
                        <img src={lang === "CH" ? VivienCH : VivienFR} alt={lang === "CH" ? t("lioSt.directors.clement.name") : t("lioLft.directors.clement.name")} />
                    </div>
                    <div className="w-full mt-12 md:mt-0 md:w-1/2 grow flex items-center">
                        <div className=" grow flex flex-col gap-8">
                            <div className={`w-1/3 h-2 ${lang === "CH" ? "bg-swiss-700" : "bg-capucine-500"}`}></div>
                            <div className={`text-4xl ${lang === "CH" ? "font-light text-black font-serif" : "font-sans font-bold text-[#252B42]"} leading-[50px] tracking-[0.2px]`}>{lang === "CH" ? t("lioSt.directors.vivien.title") : t("lioLft.directors.vivien.title")}</div>
                            <div className={`text-sm leading-[20px] tracking-[0.2px] ${lang === "CH" ? "text-black font-serif font-light" : "font-sans text-[#737373]"}`}>
                                {lang === "CH" ? t("lioSt.directors.vivien.description") : t("lioLft.directors.vivien.description")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-between">
                    <div className="w-full mt-12 md:mt-0 md:w-1/2 grow flex items-center">
                        <div className=" grow flex flex-col gap-8">
                            <div className={`w-1/3 h-2 ${lang === "CH" ? "bg-swiss-700" : "bg-capucine-500"}`}></div>
                            <div className={`text-4xl ${lang === "CH" ? "font-light text-black font-serif" : "font-sans font-bold text-[#252B42]"} leading-[50px] tracking-[0.2px]`}>{lang === "CH" ? t("lioSt.directors.clement.title") : t("lioLft.directors.clement.title")}</div>
                            <div className={`text-sm leading-[20px] tracking-[0.2px] ${lang === "CH" ? "text-black font-serif font-light" : "font-sans text-[#737373]"}`}>
                                {lang === "CH" ? t("lioSt.directors.clement.description") : t("lioLft.directors.clement.description")}
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 grow flex items-center justify-center">
                        <img src={lang === "CH" ? ClementCH : ClementFR} alt={lang === "CH" ? t("lioSt.directors.clement.name") : t("lioLft.directors.clement.name")} />
                    </div>
                </div>
                {lang === "CH" ? (
                    <div className="flex flex-col md:flex-row flex-between">
                        <div className="w-full md:w-1/2 grow flex items-center justify-center">
                            <img src={ThibaultCH} alt={lang === "CH" ? t("lioSt.directors.thibault.name") : t("lioLft.directors.thibault.name")} />
                        </div>
                        <div className="w-full mt-12 md:mt-0 md:w-1/2 grow flex items-center">
                            <div className=" grow flex flex-col gap-8">
                                <div className={`w-1/3 h-2 ${lang === "CH" ? "bg-swiss-700" : "bg-capucine-500"}`}></div>
                                <div className={`text-4xl ${lang === "CH" ? "font-light text-black font-serif" : "font-sans font-bold text-[#252B42]"} leading-[50px] tracking-[0.2px]`}>{lang === "CH" ? t("lioSt.directors.thibault.title") : t("lioLft.directors.thibault.title")}</div>
                                <div className={`text-sm leading-[20px] tracking-[0.2px] ${lang === "CH" ? "text-black font-serif font-light" : "font-sans text-[#737373]"}`}>
                                    {lang === "CH" ? t("lioSt.directors.thibault.description") : t("lioLft.directors.thibault.description")}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ( <></> )}
            </div>
        </div>
    );
}
