import { useTranslation } from "react-i18next";
import Title from "../../../components/Title";
import TeamComponent from "../../../components/TeamComponent";
import { frenchTeam } from "../../../data/dataFr";
import { swissTeam } from "../../../data/dataSw";

export default function Team({ lang = "FR" } : Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    
    return (
        <div id="team" className="w-full min-h-screen flex items-center justify-center bg-[#FFF]">
            <div className={`px-4 ${lang === "CH" ? "py-6" : "py-12"} w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-4`}>
                <Title title="commun.team.subTitle" subtitle="commun.team.title" description="" lang={lang} />
                {lang === "CH" ? swissTeam.map((team) => (
                    <TeamComponent
                        key={team.title}
                        lang={lang}
                        team={team}
                        t={t}
                    />
                )) : frenchTeam.map((team) => (
                    <TeamComponent
                        key={team.title}
                        lang={lang}
                        team={team}
                        t={t}
                    />
                ))}
            </div>
        </div>
    );
}
