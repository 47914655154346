import { toast } from "react-toastify";

export class Storage {
    private static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private static get(key: string) {
        try {
            const value = localStorage.getItem(key);
            if (value) return JSON.parse(value);
            return null;
        } catch (error) {
            console.error(error)
            toast.error("Une erreur est survenue lors de la récupération de vos données");
            Storage.emptyStorage();
            window.location.href = "/";
            return null;
        }
    }

    static getLanguage(): string | null {
        return this.get("lang");
    }

    static setLanguage(language: string) {
        this.set("lang", language);
    }
    
    static emptyStorage() {
        localStorage.removeItem("lang");
    }

    static clearStorage() {
        localStorage.clear();
    }
}
