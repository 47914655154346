export const frenchTeam = [
    {
        title: "amiens",
        team: [
            {
                id: 16,
                name: "Maeva",
                role: "commun.team.roles.dentaProsthetist"
            },
        ]
    },
    {
        title: "lyon",
        team: [
            {
                id: 1,
                name: "Mathilde",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 4,
                name: "Marina",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 5,
                name: "Sylvain",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 7,
                name: "Charline",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 8,
                name: "Shirley",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 9,
                name: "Pauline",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 10,
                name: "Joanna",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 11,
                name: "Lorine",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 12,
                name: "Carine",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 13,
                name: "Andrea",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 14,
                name: "Christelle",
                role: "commun.team.roles.dentaProsthetist"
            },
        ]
    },
    {
        title: "nice",
        team: [
            {
                id: 15,
                name: "Marion",
                role: "commun.team.roles.dentaProsthetist"
            },
        ]
    }
]

export const ciblesFR = [
    {
        isRight: true,
        isTop: false,
        x: 35,
        xMd: 38,
        y: 43,
        id: "lyon",
        title: "lioLft.laboratories.Lab1.title",
        description: "lioLft.laboratories.Lab1.description",
        monday: "lioLft.laboratories.Lab1.monday",
        tuesday: "lioLft.laboratories.Lab1.tuesday",
        wednesday: "lioLft.laboratories.Lab1.wednesday",
        thursday: "lioLft.laboratories.Lab1.thursday",
        friday: "lioLft.laboratories.Lab1.friday",
    },
    {
        isRight: true,
        isTop: true,
        x: 45,
        xMd: 48,
        y: 10,
        id: "amiens",
        title: "lioLft.laboratories.Lab2.title",
        description: "lioLft.laboratories.Lab2.description",
        monday: "lioLft.laboratories.Lab2.monday",
        tuesday: "lioLft.laboratories.Lab2.tuesday",
        wednesday: "lioLft.laboratories.Lab2.wednesday",
        thursday: "lioLft.laboratories.Lab2.thursday",
        friday: "lioLft.laboratories.Lab2.friday",
    },
    {
        isRight: true,
        isTop: false,
        x: 32,
        xMd: 37,
        y: 25,
        id: "nice",
        title: "lioLft.laboratories.Lab3.title",
        description: "lioLft.laboratories.Lab3.description",
        monday: "lioLft.laboratories.Lab3.monday",
        tuesday: "lioLft.laboratories.Lab3.tuesday",
        wednesday: "lioLft.laboratories.Lab3.wednesday",
        thursday: "lioLft.laboratories.Lab3.thursday",
        friday: "lioLft.laboratories.Lab3.friday",
    }
]