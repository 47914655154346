import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../data/Language";
import { Storage } from "../utils/storage";

const Lang = ({ myLang = "FR"} : { myLang: string }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(Storage.getLanguage() ?? Language.FR);

    let changeLanguage = (event: ChangeEvent<HTMLSelectElement>) => {
        let language = event.target.value;

        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                Storage.setLanguage("en");
                break;
            case Language.IT:
                setLang(Language.IT);
                i18n.changeLanguage(Language.IT);
                Storage.setLanguage("it");
                break;
            case Language.DE:
                setLang(Language.DE);
                i18n.changeLanguage(Language.DE);
                Storage.setLanguage("de");
                break;
            case Language.FR:
            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                Storage.setLanguage("fr");
                break;
        }
    };
    return (
        <div>
            <div>
                <select className={`${myLang === "CH" ? "bg-transparent text-black font-serif" : "bg-transparent text-white font-sans"} text-montserrat`} value={lang} name="language" onChange={changeLanguage}>
                    <option className="text-montserrat text-black" value={Language.FR}>{t("langs.french_d")}</option>
                    <option className="text-montserrat text-black" value={Language.EN}>{t("langs.english_d")}</option>
                    <option className="text-montserrat text-black" value={Language.IT}>{t("langs.italian_d")}</option>
                    <option className="text-montserrat text-black" value={Language.DE}>{t("langs.dutch_d")}</option>
                </select>
            </div>
        </div>
    );
};

export default Lang;
