import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

export default function Footer({ lang = "FR" }: Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    
    return (
        <div id="footer" className={`md:h-72 py-4 w-full ${lang === "CH" ? "bg-thunderbird-25/5" : "bg-white"} flex items-center justify-center`}>
            <div className="px-8 md:px-4 md:w-2/3 md:h-44 flex justify-between flex-wrap md:flex-nowrap gap-4">
                <div className="flex w-[40%] md:w-full flex-col gap-4">
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-royal-900 font-bold font-sans"} text-base mb-1`}>{t("commun.footer.informations.title")}</span>
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-[#737373] font-bold font-sans"} text-sm leading-8`}>
                        <Link smooth={true} className="cursor-pointer" to="expertise">{t("commun.footer.informations.expertise")}</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="team">{t("commun.footer.informations.team")}</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="labos">{t("commun.footer.informations.laboratories")}</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="join">{t("commun.footer.informations.contact")}</Link>
                    </span>
                </div>
                <div className="flex w-[40%] md:w-full flex-col gap-4 items-end md:items-start">
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-royal-900 font-bold font-sans"} text-base mb-1 text-right md:text-left`}>{t("commun.footer.legal.title")}</span>
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-[#737373] font-bold font-sans"} text-sm leading-8 text-right md:text-left`}>
                        <a href="/mentions">{t("commun.footer.legal.terms")}</a>
                    </span>
                </div>
                <div className="flex w-[40%] md:w-full flex-col gap-4 items-start">
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-royal-900 font-bold font-sans"} text-base mb-1 text-right md:text-left`}>{t("commun.footer.ressources.title")}</span>
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-[#737373] font-bold font-sans"} text-sm leading-8 text-right md:text-left`}>
                        <a href="https://laboratoire.lio-lft.fr/">{t("commun.footer.ressources.panel")}</a> <br />
                    </span>
                </div>
                <div className="flex flex-col w-[40%] md:w-full items-end md:items-start">
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-royal-900 font-bold font-sans"} text-base mb-7 text-center md:text-left`}>{t("commun.footer.contact.title")}</span>
                    <span className={`${lang === "CH" ? "text-black font-light font-serif" : "text-[#737373] font-bold font-sans"} text-sm`}>
                        <a href="tel:+33478172780" className="flex items-center justify-left gap-2">
                            <FontAwesomeIcon className={`${lang === "CH" ? "text-black" : "text-royal-900"}`} icon="phone" />
                            {lang === "CH" ? t("lioSt.contactDetails.phoneNum") : t("lioLft.contactDetails.phoneNum")}
                        </a>{" "}
                        <br />
                        <a target="_blank" rel="noreferrer" href="https://www.google.fr/maps/place/13+Av.+Albert+Einstein,+69100+Villeurbanne/@45.782908,4.8781576,16z/data=!3m1!4b1!4m6!3m5!1s0x47f4c01f23574a43:0x14715fa07472aff2!8m2!3d45.782908!4d4.8807325!16s%2Fg%2F11c2cgl35j?entry=ttu" className="flex items-center justify-left gap-2 -mt-3">
                            <FontAwesomeIcon className={`${lang === "CH" ? "text-black" : "text-royal-900"}`} icon="location-dot" />
                            <span dangerouslySetInnerHTML={{ __html: lang === "CH" ? t("lioSt.contactDetails.address") : t("lioLft.contactDetails.address")}}>
                            </span>
                        </a>{" "}
                        <br />
                        <a href="mailto:info@lio-lft.fr" className="flex items-center justify-left gap-2 -mt-3">
                            <FontAwesomeIcon className={`${lang === "CH" ? "text-black" : "text-royal-900"}`} icon="envelope" />
                            {lang === "CH" ? t("lioSt.contactDetails.email") : t("lioLft.contactDetails.email")}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}
