import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import France from "../../../assets/img/National/france.png";
import Suisse from "../../../assets/img/National/suisse.png";
import { Cibles, CiblesAccordeon } from "../../../components/CiblesComponents";
import Title from "../../../components/Title";
import { ciblesFR } from "../../../data/dataFr";
import { ciblesCH } from "../../../data/dataSw";

export default function Labo({ lang = "FR" }: Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    const [cibles, setCibles] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (lang === "CH") {
            setCibles({ lausanne: true });
        } else {
            setCibles({ amiens: false, lyon: true, nice: false });
        }
    }, [lang]);

    return (
        <div id="labos" className={`w-full min-h-screen flex items-center justify-center ${lang === "CH" ? "bg-white" : "bg-[#F6F7FB]"}`}>
            <div className={`px-4 ${lang === "CH" ? "py-6" : "py-12"} md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12`}>
                <Title
                    title={lang === "CH" ? "lioSt.laboratories.title" : "lioLft.laboratories.title"}
                    subtitle={lang === "CH" ? "lioSt.laboratories.subTitle" : "lioLft.laboratories.subTitle"}
                    description={lang === "CH" ? "lioSt.laboratories.description" : "lioLft.laboratories.description"}
                    lang={lang}
                />
                <div className="flex flex-col gap-12 md:gap-0 lg:flex-row justify-between">
                    <div className="w-full lg:w-1/2 h-fit flex items-center grow justify-center">
                        <div className="flex items-center justify-start relative lg:pr-8">
                            <img 
                                src={lang === "CH" ? Suisse : France} 
                                alt={lang === "CH" ? t("lioSt.laboratories.alt") : t("lioLft.laboratories.alt")}
                                className={`${lang === "FR" ? "w-full aspect-square" : "w-full"}`}
                            />
                            {lang === "CH"
                                ? ciblesCH.map((cible) => (
                                    <Cibles key={cible.id + "other"} lang={lang} cible={cible} cibles={cibles} />
                                )) : ciblesFR.map((cible) => (
                                    <Cibles key={cible.id + "other"} lang={lang} cible={cible} cibles={cibles} />
                                ))
                            }
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col grow gap-4 lg:pl-8 md:pt-8 lg:pt-0">
                        {lang === "CH"
                            ? ciblesCH.map((cible) => (
                                <CiblesAccordeon
                                    key={cible.id}
                                    lang={lang}
                                    cible={cible}
                                    cibles={cibles}
                                    setCibles={setCibles}
                                    t={t}
                                />
                            )) : ciblesFR.map((cible) => (
                                <CiblesAccordeon
                                    key={cible.id}
                                    lang={lang}
                                    cible={cible}
                                    cibles={cibles}
                                    setCibles={setCibles}
                                    t={t}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
