import { useTranslation } from "react-i18next";
import NavBarOtherPages from "../../components/NavBarOtherPages";
import { useEffect, useState } from "react";

export default function Mentions() {
    const [lang, setLang] = useState<string>("FR")
    const { t } = useTranslation();

    useEffect(() => {
        setLang("FR")
    }, [])

    return (
        <div className="w-full">
            <NavBarOtherPages />
            <div className="w-full max-w-[1280px] mx-auto px-4 pt-28 pb-12">
                <div className="text-[#252B42] font-bold text-4xl md:leading-[24px] tracking-[0.2px] text-center">
                    {lang === "CH" ? t("lioSt.mentionsLegales.title") : t("lioLft.mentionsLegales.title")}
                </div>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-8">
                    {lang === "CH" ? t("lioSt.mentionsLegales.description") : t("lioLft.mentionsLegales.description")}
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">
                    {lang === "CH" ? t("lioSt.mentionsLegales.edition.title") : t("lioLft.mentionsLegales.edition.title")}
                </h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.edition.description") : t("lioLft.mentionsLegales.edition.description")} <br />
                    <b dangerouslySetInnerHTML={{ __html: lang === "CH" ? t("lioSt.mentionsLegales.edition.address") : t("lioLft.mentionsLegales.edition.address")}}>
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">{lang === "CH" ? t("lioSt.mentionsLegales.cloud.title") : t("lioLft.mentionsLegales.cloud.title")}</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.cloud.description") : t("lioLft.mentionsLegales.cloud.description")}<br />
                    <b dangerouslySetInnerHTML={{ __html: lang === "CH" ? t("lioSt.mentionsLegales.cloud.address") : t("lioLft.mentionsLegales.cloud.address")}}>
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">{lang === "CH" ? t("lioSt.mentionsLegales.director.title") : t("lioLft.mentionsLegales.director.title")}</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.director.description") : t("lioLft.mentionsLegales.director.description")}<br />
                    <b dangerouslySetInnerHTML={{ __html: lang === "CH" ? t("lioSt.mentionsLegales.director.name") : t("lioLft.mentionsLegales.director.name")}}>
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">{lang === "CH" ? t("lioSt.mentionsLegales.contact.title") : t("lioLft.mentionsLegales.contact.title")}</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.contact.description") : t("lioLft.mentionsLegales.contact.description")}
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">{lang === "CH" ? t("lioSt.mentionsLegales.rgpd.title") : t("lioLft.mentionsLegales.rgpd.title")}</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.rgpd.description") : t("lioLft.mentionsLegales.rgpd.description")}
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">{lang === "CH" ? t("lioSt.mentionsLegales.important.title") : t("lioLft.mentionsLegales.important.title")}</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    {lang === "CH" ? t("lioSt.mentionsLegales.important.description") : t("lioLft.mentionsLegales.important.description")}
                </p>
            </div>
        </div>
    );
}
