import React from "react";
import ReactDOM from "react-dom/client";
import Mentions from "./pages/Other/Mentions";
import Page404 from "./pages/Other/Page404";
import Explain from "./pages/Explain/Explain";

import "./assets/css/index.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import AppLFT from "./pages/Home/AppLFT";
// import AppST from "./pages/Home/AppST";

import "./i18n";

library.add(fas);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                {/* <Route path="/" element={<AppST />} /> */}
                <Route path="/" element={<AppLFT />} />
                <Route path="/mentions" element={<Mentions />} />
                <Route path="/explain" element={<Explain />} />
                <Route path="/*" element={<Page404 />} />
            </Routes>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </BrowserRouter>
    </React.StrictMode>
);
