import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ExpertiseChProps {
    lang: string;
    datas: {
        id: number;
        title: string;
        p1: string;
        p2?: string;
        picture: string;
        icon: string;
    }[];
    t: (key: string) => string;
}

export default function ExpertiseCh({ lang, datas, t} : ExpertiseChProps) {
    return (
        <div className="flex flex-col md:flex-row gap-12">
            {datas.map((data, index) => (
                <div key={data.id} className="w-full md:w-1/3 flex flex-col gap-4">
                    <p className="font-light text-black font-serif flex gap-2 items-center text-2xl"><FontAwesomeIcon icon={data.icon as any} className="text-thunderbird-25" />{t(data.title)}</p>
                    <p className="font-light text-black font-serif">{t(data.p1)}</p>
                    {data.p2 && <p className="font-light text-black font-serif">{t(data.p2)}</p>}
                </div>
            ))}
        </div>
    )
}