interface ExpertiseFrProps {
    lang: string;
    datas: {
        id: number;
        title: string;
        p1: string;
        p2?: string;
        picture: string;
        icon: string;
    }[];
    t: (key: string) => string;
}

export default function ExpertiseFr({ lang, datas, t} : ExpertiseFrProps) {
    return (
        <div className="flex justify-around flex-col gap-12">
            {datas.map((data, index) => (
                <div key={data.id} className="w-full flex flex-col md:flex-row justify-between">
                    {index % 2 !== 0 ? (
                        <>
                            <div className="w-full md:hidden">
                                <img src={data.picture} alt="expertise" className="w-full max-h-[40vh] object-cover" />
                            </div>
                            <div className="w-full md:w-2/3 flex flex-col gap-8 md:pr-8 mt-8 md:mt-0">
                                <span className="uppercase text-[#010B70] font-bold text-2xl leading-[32px] tracking-[0.1px]">{t(data.title)}</span>
                                <div className="flex flex-col gap-4">
                                    <span className="text-sm leading-[20px] tracking-[0.2px]">{t(data.p1)}</span>
                                    {data.p2 && <span className="text-sm leading-[20px] tracking-[0.2px]">{t(data.p2)}</span>}
                                </div>
                            </div>
                            <div className="hidden md:block md:w-1/2">
                                <img src={data.picture} alt="expertise" className="w-full" />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="w-full md:w-1/3">
                                <img src={data.picture} alt="expertise" className={`w-full max-h-[40vh] md:max-h-auto object-cover ${index === 0 && "aspect-square"}`} />
                            </div>
                            <div className="w-full md:w-2/3 flex flex-col gap-8 md:pl-8 mt-8 md:mt-0">
                                <span className="uppercase text-[#FF0101] font-bold text-2xl leading-[32px] tracking-[0.1px]">{t(data.title)}</span>
                                <div className="flex flex-col gap-4">
                                    <span className="text-sm leading-[20px] tracking-[0.2px]">{t(data.p1)}</span>
                                    {data.p2 && <span className="text-sm leading-[20px] tracking-[0.2px]">{t(data.p2)}</span>}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}