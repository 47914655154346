import React, { useEffect } from "react";
import LogoLioFR from "../assets/img/logoLio.png";
import LogoLioCH from "../assets/img/Lio_frame_2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll"
import { useTranslation } from "react-i18next";

export default function NavBar({ lang = "FR" }: Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    const [withColor, setWithColor] = React.useState(false);

    useEffect(() => {
        function handleScroll() {
            const scrollY = window.scrollY;
            if (scrollY < window.innerHeight) setWithColor(false);
            else setWithColor(true);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div
            id={lang === "FR" ? "navBarHeader" : ""}
            className={`h-16 w-full flex justify-center items-center py-2 fixed top-0 left-0 z-50 ${
                withColor ? "bg-[#fff] border-b border-b-capucine-700" : "bg-transparent backdrop-blur-sm border-b border-b-transparent"
            } transition-all duration-300`}
        >
            <div className="flex flex-row items-center w-full md:w-auto px-4 md:px-0 justify-between gap-8 lg:gap-36">
                <img src={lang === "FR" ? LogoLioFR : LogoLioCH} alt="logo lio" className="h-12" />
                <div className="hidden md:flex items-center justify-between gap-4 md:gap-11">
                    <div className={`py-0.5 ${lang === "FR" ? "border-t border-b border-capucine-700" : ""}`}>
                        <Link smooth={true} to="header" className={`cursor-pointer text-sm  text-royal-975 hover:text-royal-975/60 font-sans font-bold transition-all leading-[24px] tracking-[0.2px]`}>
                            {t("commun.nav.home")}
                        </Link>
                    </div>
                    <div className={`py-0.5 ${lang === "FR" ? "border-t border-b border-capucine-700" : ""}`}>
                        <Link smooth={true} to="expertise" className={`cursor-pointer text-sm text-royal-975 hover:text-royal-975/60 font-sans font-bold transition-all leading-[24px] tracking-[0.2px]`}>
                            {t("commun.nav.expertise")}
                        </Link>
                    </div>
                    <div className={`py-0.5 ${lang === "FR" ? "border-t border-b border-capucine-700" : ""}`}>
                        <Link smooth={true} to="labos" className={`cursor-pointer text-sm text-royal-975 hover:text-royal-975/60 font-sans font-bold transition-all leading-[24px] tracking-[0.2px]`}>
                            {lang === "FR" ? t("commun.nav.labo") : t("commun.nav.laboST")}
                        </Link>
                    </div>
                </div>
                <div className="flex items-center justify-between w-fit gap-4 md:gap-7">
                    <a href="https://laboratoire.lio-lft.fr/" className={`text-sm leading-[22px] tracking-[0.2px] transition-all text-royal-975 hover:text-royal-975/60 font-sans font-bold`}>
                        <span className="hidden md:block">{t("commun.nav.customer")}</span>
                        <FontAwesomeIcon icon="user" className="md:hidden" />
                    </a>
                    <a href="https://laboratoire.lio-lft.fr/register" className={`text-sm leading-[22px] tracking-[0.2px]  py-3.5 px-6 transition-all text-white bg-royal-975 hover:bg-royal-975/80 font-sans font-bold rounded-[5px]`}>
                        <span className="hidden md:block">{t("commun.nav.register")} <FontAwesomeIcon icon="arrow-right" className="ml-2" /></span>
                        <FontAwesomeIcon icon="plus" className="md:hidden" />
                    </a>
                </div>
            </div>
        </div>
    );
}
