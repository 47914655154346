import { useTranslation } from "react-i18next";
import Seo from "../../../assets/img/Header/final_lio_header.mp4"
import HeaderDentist from "../../../assets/img/Header/dentist_header.jpg"

export default function Header({ lang = "FR" } : Readonly<{ lang?: string }>) {
    const { t } = useTranslation();

    return (
        <div id={lang === "CH" ? "" : "header"} className="relative w-full min-h-full flex items-center justify-center bg-[#73737311]">
            {lang === "FR" ? (
                <video
                    autoPlay
                    muted={true}
                    className="h-full w-full md:static mt-12"
                >
                    <source src={Seo} type="video/mp4" />
                    Votre navigateur ne prend pas en charge la balise video.
                </video>
            ) : (
                <div 
                    className="h-screen w-full bg-white relative"
                    style={{
                        backgroundImage: `url(${HeaderDentist})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}
                >
                    <div className="absolute top-0 left-0 w-full h-full bg-white/75"></div>
                </div>
            )}
            <div className="absolute top-1/2 -translate-y-1/2 left-0 px-4 md:px-0 md:left-12 flex flex-col gap-4 lg:gap-8">
                <div className="h-6 overflow-hidden relative">
                    <div className={`absolute left-0 top-0 uppercase text-base  leading-[24px] tracking-[0.1px] ${lang === "CH" ? "font-light text-black font-serif" : "font-bold text-royal-975 font-sans"}`} id={lang === "CH" ? "" : "titleHeader"}>
                        {lang === "CH" 
                            ? t("lioSt.header.subTitle")
                            : t("lioLft.header.subTitle")
                        }
                    </div>
                </div>
                <div className={`text-3xl lg:text-6xl lg:leading-[80px] tracking-[0.2px] w-full md:w-[60%] ${lang === "CH" ? "font-light text-black font-serif" : "font-bold text-royal-999 font-sans"}`} id={lang === "CH" ? "" : "title"}>
                    {lang === "CH" 
                        ? t("lioSt.header.title")
                        : t("lioLft.header.title")
                    }
                </div>
                <div 
                    id={lang === "CH" ? "" : "subtitle"}
                    className={`text-sm sm:text-lg xl:text-xl sm:leading-[30px] tracking-[0.2px] w-[60%] md:w-[60%] ${lang === "CH" ? "font-light text-black font-serif" : "text-royal-25 font-sans"}`}
                    dangerouslySetInnerHTML={{ __html: lang === "CH" 
                            ? t("lioSt.header.description")
                            : t("lioLft.header.description")
                    }}>
                </div>
                <div className="flex flex-row gap-2.5">
                    <a
                        href="https://laboratoire.lio-lft.fr/register"
                        className={`py-2 px-4 md:py-4 md:px-10 text-sm leading-[22px] tracking-[0.2px] transition-all ${lang === "CH" ? "font-light bg-thunderbird-700 hover:bg-thunderbird-700/80 text-white font-serif" : "rounded-[5px] bg-royal-975 hover:bg-royal-975/80 text-white font-sans font-bold button"}`}>
                        {lang === "CH" 
                            ? t("lioSt.header.buttonPrimary")
                            : t("lioLft.header.buttonPrimary")
                        }
                    </a>
                    <a
                        href="https://laboratoire.lio-lft.fr/"
                        className={`py-2 px-4 md:py-4 md:px-10 text-sm transition-all border leading-[22px] tracking-[0.2px] ${lang === "CH" ? "font-light  border-black text-black font-serif bg-transparent hover:bg-white/50" : "rounded-[5px] text-royal-975  border-[#010B70] font-bold bg-white hover:bg-white/70 button"}`}
                    >
                        {lang === "CH" 
                            ? t("lioSt.header.buttonSecondary")
                            : t("lioLft.header.buttonSecondary")
                        }
                    </a>
                </div>
            </div>
        </div>
    );
}
