import BarsConcept from "../assets/img/Team/thumb-concept-3.png";

interface TeamComponentProps {
    lang: string
    team: {
        title: string,
        team: {
            id: number,
            name: string
            role: string
        }[]
    },
    t:  (key: string) => string
}

export default function TeamComponent({ lang, team, t } : Readonly<TeamComponentProps>) {
    return (
        <>
            {lang === "FR" ? (
                <h2 className={`text-royal-999 font-bold font-sans text-2xl`}>{t(`commun.team.labs.${team.title}`)}</h2>
            ) : (
                <></>
            )}
            <div className="flex justify-center xl:justify-between flex-wrap w-full gap-4">
                {team.team.map((member) => (
                    <div className={`w-full min-[432px]:w-[48%] md:w-[30%] lg:w-[32%] xl:w-[24%] h-36`} key={member.id}>
                        <div className={`w-full h-full relative overflow-hidden rounded-[20px] bg-white`}>
                        <div className={`absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white/0 via-white to-royal-950/25 opacity-50 rounded-[20px]`}></div>
                            <img src={BarsConcept} alt="bars concept" className="w-full aspect-square absolute bottom-0 left-0 right-0 opacity-100" />
                            <div className={`absolute bg-white opacity-100 rounded-[20px] top-2 left-2 w-[calc(100%-16px)] h-[calc(100%-16px)]`}></div>
                            <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full flex flex-col items-center justify-center z-40">
                                <span className={`text-2xl leading-[32px] tracking-[0.1px] font-bold text-royal-925 text-center`}>{member.name}</span>
                                <div className="flex items-start gap-1">
                                    <span className={`text-center text-base leading-[20px] tracking-[0.2px] font-sans font-medium text-royal-925 opacity-60`} dangerouslySetInnerHTML={{ __html : t(member.role)}}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}