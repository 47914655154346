import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cible from "../assets/img/National/cible.png";

interface CibleUniqueProps {
    isRight: boolean;
    isTop: boolean;
    x: number,
    xMd: number,
    y: number,
    id: string,
    title: string,
    description: string,
    monday: string,
    tuesday: string,
    wednesday: string,
    thursday: string,
    friday: string,
}

interface CibleProps {
    lang: string;
    cible: CibleUniqueProps;
    cibles: { [key: string]: boolean };
}

interface CibleAccordeonProps {
    lang: string;
    cible: CibleUniqueProps;
    cibles: { [key: string]: boolean };
    setCibles: (cibles: { [key: string]: boolean }) => void;
    t: (key: string) => string;
}

export function Cibles({ lang, cible, cibles } : CibleProps) {
    const positionStyles = {
        ...(cible.isTop ? { top: `${cible.y}%` } : { bottom: `${cible.y}%` }),
        ...(cible.isRight ? { right: window.innerWidth > 1024 ? `${cible.xMd}%` : `${cible.x}%` } : { left: window.innerWidth > 1024 ? `${cible.xMd}%` : `${cible.x}%` }),
    };

    return (
        <img
            src={Cible}
            alt="cible lausanne"
            className={`origin-center absolute aspect-square transition-all ${
                lang === "CH" 
                    ? cibles[cible.id] ? "w-[5%] opacity-100" : "w-[3%] opacity-50"
                    : cibles[cible.id] ? "w-[10%] opacity-100" : "w-[8%] opacity-50"
            }`}
            style={positionStyles}
        />
    )
}

export function CiblesAccordeon({ lang, cible, cibles, setCibles, t } : CibleAccordeonProps) {
    return (
        <div className={`${lang === "CH" ? "bg-royal-200/20" : "bg-royal-200/20"} px-[25px] py-3 2xl:px-[50px] 2xl:py-5 rounded-2xl`}>
            <button
                onClick={() => lang === "FR" && setCibles({ ...cibles, [cible.id]: !cibles[cible.id] })}
                className="flex justify-between items-center w-full"
            >
                <span className={`text-2xl 2xl:text-3xl text-[#001866] font-bold leagin-[50px] tracking-[0.2px]`}>{t(cible.title)}</span>
                {lang === "FR" ? (
                    <FontAwesomeIcon icon={cibles[cible.id] ? "chevron-up" : "chevron-down"} className={`text-[#001866] h-4 2xl:h-6 w-4 2xl:w-6`} />
                ) : (
                    <></>
                )}
            </button>
            <div className={`flex flex-col overflow-hidden transition-all duration-700 ${cibles[cible.id] ? "h-48 pt-4" : "h-0"}`}>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866] font-normal"} leading-[20px] tracking-[0.2px] text-sm mb-2`}>
                    {t(cible.description)}
                </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866] font-semibold"} text-sm mb-1`}>{t("commun.laboratories.hours")} </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866]"} text-sm`}>
                    <span className="font-semibold">{t("commun.laboratories.monday")}</span> {t(cible.monday)}
                </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866]"} text-sm`}>
                    <span className="font-semibold">{t("commun.laboratories.tuesday")}</span> {t(cible.tuesday)}
                </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866]"} text-sm`}>
                    <span className="font-semibold">{t("commun.laboratories.wednesday")}</span> {t(cible.wednesday)}
                </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866]"} text-sm`}>
                    <span className="font-semibold">{t("commun.laboratories.thursday")}</span> {t(cible.thursday)}
                </span>
                <span className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#001866]"} text-sm`}>
                    <span className="font-semibold">{t("commun.laboratories.friday")}</span> {t(cible.friday)}
                </span>
            </div>
        </div>
    )
}