import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Lang from "../../../components/Lang";

export default function Credits({ lang = "FR" }: Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    return (
        <div id="credits" className={`flex items-center justify-center w-full ${lang === "CH" ? "bg-thunderbird-50" : "bg-capucine-50"} md:h-16`}>
            <div className="w-2/3 md:h-6 flex flex-col md:flex-row gap-4 md:gap-0 py-4 md:py-0 justify-between items-center">
                <span className={`${lang === "CH" ? "text-black font-serif font-light" : "text-royal-25 font-sans font-bold"} text-center md:text-left  text-sm`}>
                    {lang === "CH"
                        ? t("lioSt.footer.credits")
                        : t("lioLft.footer.credits")
                    }
                </span>
                <div className={`flex items-center justify-end gap-4 ${lang === "CH" ? "text-black" : "text-royal-900"}`}>
                    <Lang 
                        myLang={lang}
                    />
                    <a href={lang === "CH" ? t("lioSt.footer.linkdin") : t("lioLft.footer.linkdin")} target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a href={lang === "CH" ? t("lioSt.footer.instagram") : t("lioLft.footer.instagram")} target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href={lang === "CH" ? t("lioSt.footer.facebook") : t("lioLft.footer.facebook")} target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </div>
            </div>
        </div>
    );
}
