export default function Explains() {
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden h-5/6">
            <div className="relative py-3 mx-auto w-full md:w-1/2 h-5/6">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl h-5/6"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-autovh-5/6">
                    <h1 className="text-3xl text-royal-900 font-semibold text-center mb-6">Plate-forme Lio</h1>
                    <div className="w-2/3 mx-auto text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at viverra quam. Maecenas id maximus magna. Fusce ipsum quam, pulvinar at euismod a, imperdiet sit amet neque. Morbi faucibus, dui ac lobortis tempor, ipsum velit scelerisque quam, nec varius purus lacus at arcu. In hac habitasse platea dictumst. Suspendisse volutpat bibendum diam eu eleifend.</div>
                    <div className="w-2/3 mx-auto text-justify mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at viverra quam. Maecenas id maximus magna. Fusce ipsum quam, pulvinar at euismod a, imperdiet sit amet neque. Morbi faucibus, dui ac lobortis tempor, ipsum velit scelerisque quam, nec varius purus lacus at arcu. In hac habitasse platea dictumst. Suspendisse volutpat bibendum diam eu eleifend.</div>
                    <div className="flex justify-between w-full mx-auto mt-12">
                        <a href="https://laboratoire.lio-lft.fr/register" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-royal-900 shadow-md shadow-royal-900/20 hover:shadow-lg hover:shadow-royal-900/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none text-white" type="button">
                            Inscrivez-vous !
                        </a>
                        <a href="https://laboratoire.lio-lft.fr/" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-capucine-700 text-white shadow-md shadow-capucine-700/20 hover:shadow-lg hover:shadow-capucine-600/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none">
                            Connectez-vous !
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
