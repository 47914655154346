import { useState } from "react";
import { toast } from "react-toastify";

import pictureFromLio from "../../../assets/img/pictureFromLio.jpeg";
import { useTranslation } from "react-i18next";

const privateURL = process.env.REACT_APP_PRIVATE_URL;

export default function Join({ lang = "FR" }: Readonly<{ lang?: string }>) {
    const { t } = useTranslation();
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        content: ""
    })

    const handleSubmission = async (e: any) => {
        e.preventDefault();

        try {
            const response = await fetch(`${privateURL}/mailer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            await response.json();
            toast.success("Votre message a bien été envoyé !")
            setData({
                name: "",
                email: "",
                subject: "",
                content: ""
            })
        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue, veuillez réessayer plus tard.")
        }
    }


    const classNameInput = `w-full
    py-3
    px-4
    bg-[#fff]
    ${lang === "CH" ? "font-light text-black focus:ring-offset-black" : "font-bold text-royal-975 focus:ring-offset-royal-975 rounded-[5px]"}
    text-sm
    leading-[22px]
    tracking-[0.2px]
    border-none
    focus:outline-none
    focus:ring-2
    focus:ring-[#fff]
    focus:ring-offset-2
    focus:border-none
    transition-all
    duration-300`;

    return (
        <div id="join" className={`py-12 px-8 flex flex-col items-center justify-center ${lang === "CH" ? "bg-white" : "bg-royal-10"} gap-2.5`}>
            <div className={`text-sm leading-6 tracking-[.2px] ${lang === "CH" ? "font-light text-black/60 font-serif" : "font-sans text-royal-975/75 font-bold"}`}>{t("commun.contact.subTitle")}</div>
            <div className={`text-[40px] leading-[50px] text-center uppercase ${lang === "CH" ? "font-light text-black font-serif" : "font-sans font-bold text-royal-999"}`}>{t("commun.contact.title")}</div>
            <div className={`text-sm leading-5 tracking-[.2px] md:w-1/3 text-center ${lang === "CH" ? "font-light text-black font-serif" : "font-sans text-royal-25 font-normal"}`}>
                {t("commun.contact.description")}
            </div>

            <div className="mb-6 md:mb-0 md:mt-6 flex flex-col md:flex-row justify-center relative md:w-full md:max-w-[1280px] gap-8 md:gap-0">
                <img
                    src={pictureFromLio}
                    alt="join us"
                    className={`w-full md:w-1/3 mt-8 relative md:ml-6 z-20 h-fit ${lang === "CH" ? "" : "rounded-[5px]"} `}
                />
                <form onSubmit={handleSubmission} className={`relative w-full md:w-1/2  ${lang === "CH" ? "bg-thunderbird-25/10 text-black font-serif font-light" : "rounded-2xl bg-[#929EC34D] text-royal-975 font-sans font-bold"} p-4 md:p-10 text-justify md:-ml-6 z-10 flex flex-col gap-4`}>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="nomSociete" className="text-sm leading-6 tracking-[.2px] ">
                            {t("commun.contact.form.name")}
                        </label>
                        <input
                            className={classNameInput}
                            id="nomSociete"
                            type="text"
                            placeholder={t("commun.contact.form.namePlaceholder")}
                            value={data.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="email" className="text-sm leading-6 tracking-[.2px] ">
                            {t("commun.contact.form.email")}
                        </label>
                        <input
                            className={classNameInput}
                            id="email"
                            type="email"
                            placeholder={t("commun.contact.form.emailPlaceholder")}
                            value={data.email}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="object" className="text-sm leading-6 tracking-[.2px] ">
                            {t("commun.contact.form.object")}
                        </label>
                        <input
                            className={classNameInput}
                            id="object"
                            type="text"
                            placeholder={t("commun.contact.form.objectPlaceholder")}
                            value={data.subject}
                            onChange={(e) => setData({ ...data, subject: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="message" className="text-sm leading-6 tracking-[.2px] ">
                            {t("commun.contact.form.message")}
                        </label>
                        <textarea
                            className={classNameInput}
                            id="message"
                            placeholder={t("commun.contact.form.messagePlaceholder")}
                            value={data.content}
                            onChange={(e) => setData({ ...data, content: e.target.value })}
                        />
                    </div>
                    <button type="submit" className={`py-4 px-10 text-sm font-bold leading-[22px] tracking-[0.2px]  ${lang === "CH" ? "font-serif bg-thunderbird-700 hover:bg-thunderbird-700/80" : "rounded-[5px] bg-capucine-500 hover:bg-capucine-500/80 font-sans"} transition-all text-white`}>
                        {t("commun.contact.form.button")}
                    </button>
                </form>
            </div>
        </div>
    );
}
