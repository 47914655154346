export const swissTeam = [
    {
        title: "amiens",
        team: [
            {
                id: 16,
                name: "Vivien",
                role: "commun.team.roles.founder"
            },
            {
                id: 16,
                name: "Thibault",
                role: "commun.team.roles.labDirectory"
            },
            {
                id: 16,
                name: "Clément",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 16,
                name: "Maeva",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 1,
                name: "Mathilde",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 4,
                name: "Marina",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 5,
                name: "Sylvain",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 7,
                name: "Charline",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 8,
                name: "Shirley",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 9,
                name: "Pauline",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 10,
                name: "Joanna",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 11,
                name: "Lorine",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 12,
                name: "Carine",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 13,
                name: "Andrea",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 14,
                name: "Christelle",
                role: "commun.team.roles.dentaProsthetist"
            },
            {
                id: 15,
                name: "Marion",
                role: "commun.team.roles.dentaProsthetist"
            },
        ]
    }
]

export const ciblesCH = [
    {
        isRight: false,
        isTop: false,
        x: 22,
        xMd: 22,
        y: 38,
        id: "lausanne",
        title: "lioSt.laboratories.Lab1.title",
        description: "lioSt.laboratories.Lab1.description",
        monday: "lioSt.laboratories.Lab1.monday",
        tuesday: "lioSt.laboratories.Lab1.tuesday",
        wednesday: "lioSt.laboratories.Lab1.wednesday",
        thursday: "lioSt.laboratories.Lab1.thursday",
        friday: "lioSt.laboratories.Lab1.friday",
    }
]