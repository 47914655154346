import { useTranslation } from "react-i18next";

export default function Title({ title, subtitle, description, lang } : { title: string, subtitle: string, description: string, lang: string }) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-start justify-start gap-4">
            <div className={`${lang === "CH" ? "text-black/60 font-light font-serif" : "font-sans text-[#010B72]/65 font-bold"} text-sm leading-[24px] tracking-[0.2px]`}>{t(title)}</div>
            <div className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#252B42] font-bold"} text-4xl md:leading-[24px] tracking-[0.2px]`}>{t(subtitle)}</div>
            <div className={`${lang === "CH" ? "text-black font-light font-serif" : "font-sans text-[#737373] font-normal"} leading-[20px] tracking-[0.2px] text-sm md:w-2/3`}>
                {t(description)}
            </div>
        </div>
    )
}