import NavBar from "../../components/NavBar";
import Credits from "./components/Credits";
import Expertise from "./components/Expertise";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Join from "./components/Join";
import Labo from "./components/Labo";
// import Opinion from "./components/Opinion";
import Team from "./components/Team";
import Vivien from "./components/Directors";

function AppLFT() {
    return (
        <div>
            <NavBar lang="FR" />
            <Header lang="FR" />
            <Expertise lang="FR" />
            <Vivien lang="FR" />
            <Team lang="FR" />
            <Labo lang="FR" />
            <Join lang="FR" />
            <Footer lang="FR" />
            <Credits lang="FR" />
        </div>
    );
}

export default AppLFT;
